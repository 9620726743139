
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { Reserve, SaleOrder } from '@/entities/sales'

@Component
  export default class AlertReserveSell extends Cell {
  alert = {
    background: '',
    color: '',
    icon: '',
    tooltip: '',
    value: 0,
  }

  async mounted () {
    const { item } = this

    if (!item?.id || !item?.status?.isActive) return

    if (item instanceof Reserve) {
      await this.getReserveInfo()
    }

    if (item instanceof SaleOrder) {
      await this.getSellInfo()
    }
  }

  async getSellInfo () {
    const { item, fetchData } = this

    const data = await fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { status: { status: { name: { _eq: 'pending' } } } },
          { deal: { id: { _eq: item.deal.id } } },
        ],
      },
      force: true,
    })

    if (!data?.length) {
      this.setAlert()
    }
  }

  setAlert () {
    this.alert = {
      background: 'white',
      color: 'green',
      icon: 'mdi-cash',
      tooltip: 'Pagos validados',
      value: 0,
    }
  }

  async getReserveInfo () {
    const { item, fetchData } = this

    const data = await fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { status: { status: { name: { _eq: 'pending' } } } },
          { id_process_record: { _eq: item.id } },
          { process: { table_name: { _eq: 'reserve' } } },
        ],
      },
      force: true,
    })

    if (!data?.length) {
      this.setAlert()
    }
  }

  get background () {
    const { alert: { background } } = this
    return background
  }

  get color () {
    const { alert: { color } } = this
    return color
  }

  get icon () {
    const { alert: { icon } } = this
    return icon
  }

  get tooltip () {
    const { alert: { tooltip } } = this
    return tooltip
  }

  get value () {
    const { alert: { value = 0 } } = this
    return value
  }
  }
